








import { Component, Vue } from "vue-property-decorator";
import ActasConcejo from "@/components/Municipalidad/ActasConcejo.vue";

@Component({
  components: {
    ActasConcejo
  }
})
export default class ActasConcejoView extends Vue {}
