








import { Component, Vue } from "vue-property-decorator";
import ConcursosPublicos from "@/components/Municipalidad/ConcursosPublicos.vue";

@Component({
  components: {
    ConcursosPublicos
  }
})
export default class ConcursosPublicosView extends Vue {}
