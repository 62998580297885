








import { Component, Vue } from "vue-property-decorator";
import Concejo from "@/components/Municipalidad/Concejo.vue";

@Component({
  components: {
    Concejo
  }
})
export default class ConcejoView extends Vue {}
