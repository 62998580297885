

















































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
@Component({
  components: {}
})
export default class ConsursosPublicos extends Vue {
  private win = window;
  private text: string = "";
  private concursos: any = [];
  private selected: any = null;
  private verModal: boolean = false;
  private busqueda: any = null;

  @Watch("text")
  private watchText(newValue: any) {
    this.buscar();
  }

  private mounted() {
    document.title = "Concursos Públicos - Municipalidad de Quilpué";
    this.getConcursos();
  }

  private verDetalle(item: any) {
    this.selected = item;
    this.verModal = true;
  }

  private getConcursos() {
    this.$axios
      .get("concursos")
      .then(res => {
        this.concursos = res.data;
        for (let i = 0; i < this.concursos.length; i++) {
          let base = process.env.VUE_APP_BASE_URL;
          if (this.concursos[i].resultados) {
            this.concursos[i].resultados = base + this.concursos[i].resultados;
          }
        }
        this.busqueda = res.data;
        this.selected = this.busqueda[0];
      })
      .catch((err: any) => {
        //this.routerGo("Home");
        console.log(err);
      });
  }

  @Debounce(500)
  private buscar() {
    this.$q.loading.show({
      delay: 50 // ms
    });
    if (this.text.trim().length >= 1) {
      let result: any = [];
      for (let i = 0; i < this.concursos.length; i++) {
        let concurso = this.concursos[i];
        let contiene = false;
        if (
          concurso.nombre.toUpperCase().indexOf(this.text.toUpperCase()) >= 0
        ) {
          contiene = true;
        } else if (
          concurso.codigo.toUpperCase().indexOf(this.text.toUpperCase()) >= 0
        ) {
          contiene = true;
        } else if (
          concurso.descripcion.toUpperCase().indexOf(this.text.toUpperCase()) >=
          0
        ) {
          contiene = true;
        }
        if (contiene) {
          result.push(concurso);
        }
      }
      if (result.length > 0) {
        this.busqueda = result;
        this.selected = this.busqueda[0];
      } else {
        this.busqueda = null;
      }
    } else if (this.text.trim() == "") {
      this.busqueda = this.concursos;
    }
    this.$q.loading.hide();
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    // var anho_termino = fecha_termino.substring(0, 4);
    // var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    // var dia_termino = fecha_termino.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }
    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
}
